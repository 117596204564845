// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hello {
    text-align: center;
}

@media (min-width: 768px) {
    .hello {
      text-align: start;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/bussiness/second.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI;MACE,iBAAiB;IACnB;EACF","sourcesContent":[".hello {\n    text-align: center;\n}\n\n@media (min-width: 768px) {\n    .hello {\n      text-align: start;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
