





import React from 'react';
import img from '../../assets/qr.png';
import bg2 from "../../assets/bg2.png";
import bg1 from "../../assets/bg1.png";
import Navbar from '../../components/common/Navbar';
import Footer from '../../components/common/Footer';

const Myeventdetails = () => {
  return (
    <div>
      <Navbar/>
      <div
        style={{ backgroundImage: `url(${bg1}), url(${bg2})` }}
        className="bg-black bg-cover min-h-screen p-3   text-white"
      >
        {/* Event Card */}
        <div className="bg-[#262626] mb-5 p-3 mt-[5rem] sm:p-6 rounded-lg w-11/12 md:w-10/12 mx-auto shadow-lg">
          {/* Header Section */}
          <div className="bg-[#262626] px-3 sm:px-6 py-3 rounded-lg w-[93%] mx-auto flex flex-row items-center space-y-6 md:space-y-0 space-x-2 sm:space-x-6">
            {/* Event Thumbnail */}
            <div className="w-[30%]">
              <img
                src="https://s3-alpha-sig.figma.com/img/af00/0279/4aa3615214433c16ab9c7a4c80034966?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nC8nwGnGiuPjKPsjre2CPjcRw~zmhK~LjJt5NMuU5B6GxUUAug5LXN5akWAHaGXL5dJc5fllZn-sPDWeSitIScpWufYvIV1jQ2npFb~Bjs5RUhlzCtUXXvtpWeoBUcE0O-Lbh7-aqgHwaTadZm6Eb-CA3-b~k77ULooEQ6omochJO8f~iRkyGy1vjmuuVxoIrj5SyZ8-e25ulC130dBFQ~Hb~bUNSpmG1lzvfmU4WRhHdB54FyrAqNIVLfuzEE9e2qR0WdMwO2i2GNaYwdd7vRAwToLlghe~MNkD18GJ8v4q7OO39ahogy0BXwXT4DEQXJM7CheKq67QPPW~melBBw__"
                alt="Event Thumbnail"
                className="rounded-md border border-gray-700 h-[6rem] sm:h-[15rem] w-[112px] sm:w-full "
              />
            </div>

            {/* Event Details */}
            <div className="w-full md:w-[70%]">
              <h2 className="text-[8px] sm:text-xl lg:text-2xl font-bold  text-start md:text-left">Mehendi Ceremony</h2>
              <h2 className="text-[6px] sm:text-xl md:text-2xl lg:text-3xl font-bold  text-start md:text-left">The Road to Jobs and Internships: Starting with LinkedIn Webinar</h2>
              <div className="text-[4px] sm:text-xs lg:text-lg text-gray-300 mt-2 text-start md:text-left">
                
                <span className="text-[#f5167e] text-[8px] sm:text-lg">
                  <i className="fas fa-calendar-alt mr-2 mb-1 "></i>24 Oct 2023 | 5:30 PM - 28 Oct 2023 | 9:30 PM
                </span>
                <br/>
                <span className="text-white  text-[8px] sm:text-lg">
                  <i className="fas fa-map-marker-alt mr-2 "></i>Peacock Garden, Entertainment Paradise, Tonk Road
                </span>
                
              </div>
              <div className="mt-2 flex flex-col md:flex-row justify-between items-center gap-4">
                <div className="flex  flex-row gap-4">
                  <p className="text-[8px] sm:text-sm md:text-xl font-semibold text-white">
                    <strong className="font-thin text-[#cccccc]">Total Amount:</strong> Rs. 499
                  </p>
                  <p className="text-[8px] sm:text-sm md:text-xl font-semibold text-white">
                    <strong className="font-thin text-[#cccccc]">Tickets:</strong> 6
                  </p>
                </div>
                {/* <button className="bg-[#f5167e] hover:bg-[#f5167e] text-white font-semibold px-6 py-2 rounded-3xl">
                  View Details
                </button> */}
              </div>
            </div>
          </div>

         <div className='px-10'>
           {/* Divider */}
           <div className="border-t border-dotted border-white my-6"></div>

{/* Ticket Details */}
<div className="text-[10px]  md:text-base text-white ">
  {/** Ticket Rows */}
  {[...Array(4)].map((_, i) => (
    <div key={i} className="flex justify-between mb-4">
      <div className="flex flex-col">
        <span>Silver Level 3</span>
        <span className="text-gray-400 text-xs">Quantity: 1</span>
      </div>
      <span>₹1500</span>
    </div>
  ))}

  {/* Total Price */}
  <div className="border-t border-dotted border-white mt-4 pt-2 flex justify-between text-lg font-semibold">
    <span className="text-[#cccccc] font-thin">Total Price:</span>
    <span>₹12000</span>
  </div>
</div>

         </div>
          {/* QR Code and Transaction ID */}
          <div className="flex flex-col items-center mt-6">
            <img src={img} alt="QR Code" className="w-32 h-32" />
            <p className="mt-2 text-gray-400">
              Transaction ID: <strong>#Eventlane</strong>
            </p>
          </div>

          {/* Action Buttons */}
          <div className="mt-6 flex flex-col gap-3 space-x-4 justify-center">
          <button className="bg-white w-[16rem]  sm:w-[20rem] mx-auto px-3 py-2 rounded-3xl text-[#f5167e] font-semibold">
            Download Ticket
          </button>
          <button className="bg-white  px-6 py-2 w-[16rem]  sm:w-[20rem] mx-auto rounded-3xl text-[#f5167e] font-semibold">
            Share Ticket
          </button>
        </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Myeventdetails;
