

import React, { useEffect, useState } from 'react'
import img from "../../assets/Logo.png";
import logo from "../../assets/Logo.png";
import img1 from "../../assets/navcolor.png";
import img2 from "../../assets/nav.png";
import bg1 from "../../assets/bg1.png";
import bg2 from "../../assets/bg2.png";
import bg5 from "../../assets/bg5.png";
import footer from "../../assets/footer.png";
import { Link } from 'react-router-dom';
import Navbar from '../../components/common/Navbar';

const Pagefirst = () => {

  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      
      if (window.scrollY > 100) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };


    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);



  
  const [termsAccepted, setTermsAccepted] = useState({
      eventlane: false,
      organizer: false,
    });
  
    const handleCheckboxChange = (key) => {
      setTermsAccepted((prev) => ({ ...prev, [key]: !prev[key] }));
    };
  
  

    const [earlyBirdTickets, setEarlyBirdTickets] = useState(0);
    const [selectedTickets, setSelectedTickets] = useState(0);
    const ticketPrice = 1000;
    const generalPrice = 1500;
  
    const total = earlyBirdTickets * ticketPrice || generalPrice;
  
    const handleTicketChange = (e) => {
      setEarlyBirdTickets(parseInt(e.target.value, 10));
    };
  return (
    <div>
      <Navbar/>







        <div style={{ backgroundImage: `url(${bg1}), url(${bg2})` }} className=" bg-black bg-cover text-white flex items-center justify-center">
      <div className="w-full flex flex-col max-w-5xl mt-[5rem]  rounded-lg  p-6">
      <div className="  text-white flex  justify-center">
      <div className="w-full max-w-6xl h-[15rem] sm:h-[22rem] rounded-lg sm:p-6">
        
      <div className="flex items-center  space-x-6">
          <img
            src="https://s3-alpha-sig.figma.com/img/af00/0279/4aa3615214433c16ab9c7a4c80034966?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nC8nwGnGiuPjKPsjre2CPjcRw~zmhK~LjJt5NMuU5B6GxUUAug5LXN5akWAHaGXL5dJc5fllZn-sPDWeSitIScpWufYvIV1jQ2npFb~Bjs5RUhlzCtUXXvtpWeoBUcE0O-Lbh7-aqgHwaTadZm6Eb-CA3-b~k77ULooEQ6omochJO8f~iRkyGy1vjmuuVxoIrj5SyZ8-e25ulC130dBFQ~Hb~bUNSpmG1lzvfmU4WRhHdB54FyrAqNIVLfuzEE9e2qR0WdMwO2i2GNaYwdd7vRAwToLlghe~MNkD18GJ8v4q7OO39ahogy0BXwXT4DEQXJM7CheKq67QPPW~melBBw__" 
            alt="Webinar"
            className="w-[8rem] h-[9rem] sm:w-[16rem] sm:h-[16rem] rounded-sm border-3 border-white"
          />
          <div className=''>
            <p className="text-[#cccccc] text-xs sm:text-3xl mb-1">Educational & Business</p>
            <span className=" text-sm sm:text-5xl font-semibold">
              The Road to Jobs and Internships: Starting 
            </span>
            <div className="flex flex-col items-start gap-1 sm:mt-4 sm:space-y-4">
            <div className="flex gap-2 items-center space-x-2 text-xs sm:text-3xl text-[#f5167e]">
                <i className="far fa-calendar-alt"></i>
                Sat Oct 05, 09:00 PM
              </div>
              <div className="flex gap-2 items-center space-x-2  text-xs sm:text-3xl text-white">
                <i className="fas fa-map-marker-alt"></i>
                Inner Circle Garba, Ahmedabad
              </div>
            </div>
          </div>
        </div>

        
        



      </div>
    </div> 




<div className=" flex items-center justify-center ">
          <div className="flex items-center sm:mt-12 space-x-6 sm:space-x-9">
            <div className="flex flex-col items-center">
              <div className="w-10 h-10 flex items-center justify-center bg-white rounded-r-lg rounded-t-lg text-white font-semibold">
                <i className="fas fa-check text-[#f5167e] "></i>
              </div>
              <p className="text-sm text-white mt-2">Tickets</p>
            </div>
            <div className="h-1 w-8 sm:w-16 bg-[#f5167e]"></div>

            <div className="flex flex-col items-center">
              <div className="w-10 h-10 flex items-center justify-center bg-white rounded-r-lg rounded-t-lg text-white font-semibold">
                <i className="fas fa-check text-[#f5167e] "></i>
              </div>
              <p className="text-sm text-white mt-2">Summary</p>
            </div>
            <div className="h-1 w-8 sm:w-16 bg-gray-600"></div>

            <div className="flex flex-col items-center">
              <div className="w-10 h-10 flex items-center justify-center bg-[#f5167e] rounded-r-lg rounded-t-lg text-white font-semibold">
                <i className=""></i>
              </div>
              <p className="text-sm text-gray-400 mt-2">Payment</p>
            </div>
          </div>
        </div>


    




<div className="bg-[#262626] text-white py-4 px-4 mt-20 rounded-3xl mb-5 text-xs sm:text-xl">
{/* 
<div className="text-2xl font-bold mb-4">
          <button className="text-3xl text-white">
            ←
          </button>
          Your Ticket Details</div>
       */}
  <div className="bg-[#262626] text-white p-3 rounded-lg  mx-auto">
    
      
      <div className="space-y-4">
      <div className="flex justify-between mb-4">
    <div className="flex flex-col">
      <span>Early Bird Day Ticket</span>
      <span className="text-gray-400 text-xs">No Cover</span>
    </div>
    <span>₹1500</span>
  </div>
  <div className="flex justify-between mb-4">
    <div className="flex flex-col">
      <span>General Day Ticket</span>
      <span className="text-gray-400 text-xs">No Cover</span>
    </div>
    <span>₹1500</span>
  </div>
        <div className="flex justify-between">
          <p>Add. Processing Fees @ 10%</p>
          <p>Rs. 300</p>
        </div>
        <div className="flex justify-between">
          <p>Add. GST @ 10%</p>
          <p>Rs. 200</p>
        </div>
        <hr className=" border-dotted border-gray-00 my-4" />
        <div className="flex justify-between font-semibold text-lg">
          <p>Total</p>
          <p>Rs. {total}</p>
        </div>

        
      <div className="mt-6 space-y-3 text-sm sm:text-lg text-gray-400">
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={termsAccepted.eventlane}
            onChange={() => handleCheckboxChange("eventlane")}
            className="accent-[#f5167e] w-5 h-5"
          />
          I agree to Eventlane's Private  Terms and Conditions , refund policy and Privacy Policy.
        </label>
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={termsAccepted.organizer}
            onChange={() => handleCheckboxChange("organizer")}
            className="accent-[#f5167e] w-5 h-5"
          />
          I agree to Event Organizer's Terms and Conditions , refund policy and Privacy Policy.
        </label>
      </div>
        
      </div>

      
    </div>
      </div>


      
    </div>
  
    </div>
  





{/* footer */}

<div className="relative ">
      <img src={bg5} className='absolute -top-20 h-64 w-[100vw] z-0'/>
      
      <div className="relative h-[35rem] z-10">
        
        <img
          src={footer}
          className="w-full h-[35rem] object-cover "
          alt="Background"
        />
      </div>
      <div className="absolute top-0 left-0 right-0  z-40  text-white flex flex-col justify-center items-center h-[40rem] w-full">
        <h1 className="text-[2.5rem] md:text-[3.5rem] text-center">
          Are you an Event <span className="text-[#FFE047]">Organiser?</span>
        </h1>
        <h2 className="text-[1.5rem] md:text-[2rem] text-center">
          Get Your Event Live in Less Than 3 Minutes
        </h2>
        <Link to='/business'  className="block no-underline">
        <button className="z-40 text-[#f5167e] bg-white rounded-[3rem] mt-12 py-3 px-16 md:py-3 md:px-24">
          List Event
        </button>
        </Link>
      </div>


      
      <footer className="bg-[#262626] text-white py-8 md:py-16">

        
  <div className=" mx-auto h-[80vh] md:h-[45vh] ">
    
    <div className="flex-wrap md:flex md:flex-nowrap   w-[85%]  mx-auto ">

    <div className="w-full md:w-1/3 flex flex-col items-start ">
          <div className="text-2xl font-bold mb-1 sm:mb-4 ">
            <img src={logo} alt="Eventlane Logo" className="w-3/4  md:mx-0" />
          </div>
          <p className="text-sm ">
            Eventlane is a global self-service ticketing platform for live experiences that allows anyone to create, share, find, and attend events that fuel their passions and enrich their lives.
          </p>
          <div className="flex justify-center md:justify-start mt-1 sm:mt-4 text-xl md:text-3xl space-x-4">
            <a href="#" className="text-white ">
              <i className="fab fa-facebook" ></i>
            </a>
            <a href="#" className="text-white ">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#" className="text-white ">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
    
      <div className="flex gap-[4rem] w-full md:w-1/3">
        
        
        
        <div className=" w-1/2 flex-col justify-end items-end">
          <h3 className="text-lg font-semibold mb-4 text-start">Plan Events</h3>
          <h4 className="space-y-2 text-sm"></h4>
            <div className='flex-col gap-2'>
            <p>Create and Set </p>
            <p>Sell Tickets</p>
            <p>Online RSVP</p>
            <p>Online Events</p>
          
            </div>
        </div>

        <div className=" w-1/2 flex-col text-start justify-center items-center">
          <h3 className="text-lg font-semibold mb-4 text-start">Eventtick</h3>
          <h4 className="space-y-2 text-sm"> </h4>
            <p>About Us</p>
            <p>Press</p>
            <p>Contact</p>
            <p>Online Events</p>
         
        </div>
      </div>

      
      <div className="flex  gap-[4rem] w-full md:w-1/3">
        
        

        
        <div className="w-full flex flex-col items-start">
          <h3 className="text-lg font-semibold mb-4">Stay In The Loop</h3>
          <p className="text-sm mb-4">
            Join our mailing list to stay in the loop with our newest events and concerts.
          </p>
          <div className="flex items-center relative w-full">
            <div className="flex items-center border-2 border-[#f5167e] bg-white rounded-[2rem] w-full px-4 py-2">
              <input
                type="text"
                placeholder="Enter Your Email Address"
                className="w-full bg-transparent text-gray-700 placeholder-gray-500 focus:outline-none py-2"
              />
            </div>
            <button className="absolute right-2 px-4 py-2 bg-[#f5167e] rounded-[2rem] text-white ">
              Subscribe now
            </button>
          </div>
        </div>
      </div>
    </div>

   
    
  </div>
  <div className="w-[85%] mx-auto border-t border-gray-600 pt-6 text-center text-sm text-gray-400">
      Copyright © 2024 Softtura. All rights reserved.
    </div> 
</footer>

    </div>


    <div className="bg-[#262626] text-white p-2 flex justify-between mx-auto max-w-screen-full fixed bottom-0 z-40 w-full">
  <div className='flex justify-around w-full'>
  <div className="text-right flex flex-col items-start">
    <p className="text-lg">Total</p>
    <p className="text-2xl font-semibold">
      Rs. {selectedTickets * 1000 || 1500}
    </p>
  </div>

  <div>
  <Link to='/page-third'  className="block no-underline">
    <button
      className={`bg-[#f5167e] text-white  transition-colors mt-6 py-3 px-6 rounded-3xl font-medium `}
     
    >
      Pay Now
    </button>
    </Link>
  </div>
  </div>
</div>




    </div>
  )
}

export default Pagefirst







