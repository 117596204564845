// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input:-webkit-autofill {
    background-color: black !important; /* Ensure background stays black */
    color: white !important;          /* Ensure text color is white */
    box-shadow: 0 0 0px 1000px black inset !important; /* Prevent browser default styles */
    -webkit-text-fill-color: white !important; /* Adjust autofill text color */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/loginpages/register.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC,EAAE,kCAAkC;IACtE,uBAAuB,WAAW,+BAA+B;IACjE,iDAAiD,EAAE,mCAAmC;IACtF,yCAAyC,EAAE,+BAA+B;EAC5E","sourcesContent":["input:-webkit-autofill {\n    background-color: black !important; /* Ensure background stays black */\n    color: white !important;          /* Ensure text color is white */\n    box-shadow: 0 0 0px 1000px black inset !important; /* Prevent browser default styles */\n    -webkit-text-fill-color: white !important; /* Adjust autofill text color */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
