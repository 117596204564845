// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hello {
    margin-left: .5rem;
    margin-right: .5rem;
    
}

@media (min-width: 1024px) {
    .hello {
        margin-left: 8rem;
        margin-right: 8rem;
    }
  }
@media (min-width: 768px) {
    .hello {
        margin-left: 4rem;
        margin-right: 4rem;
    }
  }


.yello {
    margin-left: 0rem;
    margin-right: 0rem;
    
}

@media (min-width: 1024px) {
    .yello {
        margin-left: 8rem;
        margin-right: 8rem;
    }
  }
@media (min-width: 768px) {
    .yello {
        margin-left: 4rem;
        margin-right: 4rem;
    }
  }

  .button{
    display: flex;
    gap: 10px;
  }

  @media (min-width: 1024px) {
    .button {
        display: flex;
        gap: 3rem;
    }
  }

  .get-button{
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 100px;
    padding-right: 100px;
    font-size: 10px;
  }

  @media (min-width: 1024px) {
    .get-button {
        font-size: 20px;
        padding-top: 20px;
        padding-left: 150px;
        padding-right: 150px;
    padding-bottom: 20px;
    }
  }
  
  .mm{
    display: flex;
    gap: 0px;
  }

  @media (min-width: 1024px) {
    .mm {
        display: flex;
        gap: 10px;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/pages/eventdetails/hello.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;;AAEvB;;AAEA;IACI;QACI,iBAAiB;QACjB,kBAAkB;IACtB;EACF;AACF;IACI;QACI,iBAAiB;QACjB,kBAAkB;IACtB;EACF;;;AAGF;IACI,iBAAiB;IACjB,kBAAkB;;AAEtB;;AAEA;IACI;QACI,iBAAiB;QACjB,kBAAkB;IACtB;EACF;AACF;IACI;QACI,iBAAiB;QACjB,kBAAkB;IACtB;EACF;;EAEA;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE;QACI,aAAa;QACb,SAAS;IACb;EACF;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;EACjB;;EAEA;IACE;QACI,eAAe;QACf,iBAAiB;QACjB,mBAAmB;QACnB,oBAAoB;IACxB,oBAAoB;IACpB;EACF;;EAEA;IACE,aAAa;IACb,QAAQ;EACV;;EAEA;IACE;QACI,aAAa;QACb,SAAS;IACb;EACF","sourcesContent":[".hello {\n    margin-left: .5rem;\n    margin-right: .5rem;\n    \n}\n\n@media (min-width: 1024px) {\n    .hello {\n        margin-left: 8rem;\n        margin-right: 8rem;\n    }\n  }\n@media (min-width: 768px) {\n    .hello {\n        margin-left: 4rem;\n        margin-right: 4rem;\n    }\n  }\n\n\n.yello {\n    margin-left: 0rem;\n    margin-right: 0rem;\n    \n}\n\n@media (min-width: 1024px) {\n    .yello {\n        margin-left: 8rem;\n        margin-right: 8rem;\n    }\n  }\n@media (min-width: 768px) {\n    .yello {\n        margin-left: 4rem;\n        margin-right: 4rem;\n    }\n  }\n\n  .button{\n    display: flex;\n    gap: 10px;\n  }\n\n  @media (min-width: 1024px) {\n    .button {\n        display: flex;\n        gap: 3rem;\n    }\n  }\n\n  .get-button{\n    padding-top: 2px;\n    padding-bottom: 2px;\n    padding-left: 100px;\n    padding-right: 100px;\n    font-size: 10px;\n  }\n\n  @media (min-width: 1024px) {\n    .get-button {\n        font-size: 20px;\n        padding-top: 20px;\n        padding-left: 150px;\n        padding-right: 150px;\n    padding-bottom: 20px;\n    }\n  }\n  \n  .mm{\n    display: flex;\n    gap: 0px;\n  }\n\n  @media (min-width: 1024px) {\n    .mm {\n        display: flex;\n        gap: 10px;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
