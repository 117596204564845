import React from 'react'
import First from './First'
import Second from './Second'
import Footer from '../../components/common/Footer'
import Navbar from '../../components/common/Navbar'

const Fullbusiness = () => {
  return (
    <div>
      <Navbar/>
        <First/>
        <Second/>
       <Footer/>
    </div>
  )
}

export default Fullbusiness